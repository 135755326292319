import React, { useContext } from 'react';
import { Layout, Seo } from 'components';
import { Row, Col } from "react-grid-system";

import SideNav from '../../codes/components/side-nav';
import { appContext } from 'providers/appProvider';
import ExternalLink from '../../codes/components/external-link';
// import page style
import './resources.scss';

const ResourcesPage = () => {
    const { appConfigs } = useContext(appContext);
  return (
    <Layout indication="patient" className="page-patient-resources">
      <Seo pageTitle="Patient Resources"
      pageDescription="Find helpful resources about Butrans. See Full Prescribing Info, Med Guide & Boxed Warning." />
      <div className="body-content-container">
      <SideNav pageId="pat-resources" />
        <div className="body-content gutter-all">
      
<section className='spacer-bottom'>
						<Row>
							<Col xs={12}>
                            <h1>Your Butrans Resource Center</h1>
              <h2 id="pamphlets-and-programs-to-help-your-chronic-pain-treatment-with-butrans">Pamphlets and programs to help your treatment with Butrans</h2>
              <h2 id="helpful-resources-to-download">Helpful resources to download</h2>
								<ul className='bullet-list'>
									<li><span>The <span className='text-bold'>Butrans Medication Guide</span>
											, which gives you important safety information about
											Butrans
										</span>
									</li>
							    <ExternalLink
												externalURL='https://dailymed.nlm.nih.gov/dailymed/fda/fdaDrugXsl.cfm?setid=794aa355-66de-41b8-aedf-f2c40f6bc664&type=display#section-17'
												messageTitle={appConfigs.dailymedLinkInfo.TITLE}
												messageBody={appConfigs.dailymedLinkInfo.BODY}
												classNameLink='external-link'
												classNameOverlay={
													appConfigs.externalLinkInfo.OVERLAY_CLASS_NAME
												}
											>
												View the Butrans Medication Guide & Instructions for Use
											</ExternalLink>.
                                            <br/><br/>
                             		        <li><span>The <span className='text-bold'>Butrans Full Prescribing Information</span>
										</span>
									</li>
								
							    <ExternalLink
												externalURL='https://dailymed.nlm.nih.gov/dailymed/fda/fdaDrugXsl.cfm?setid=794aa355-66de-41b8-aedf-f2c40f6bc664&type=display'
												messageTitle={appConfigs.dailymedLinkInfo.TITLE}
												messageBody={appConfigs.dailymedLinkInfo.BODY}
												classNameLink='external-link'
												classNameOverlay={
													appConfigs.externalLinkInfo.OVERLAY_CLASS_NAME
												}
											>
													View the Full Prescribing Information
											</ExternalLink>.<br/><br/>
                                            <li><span>Patch Application Skin Site Tracker (printable)</span><br/>
								         
                                        <a href="/pdfs/ApplicationSiteTracker.pdf" target="_blank" rel="noreferrer" className="external-link no-underline">
Track your application sites for Butrans</a>.<br/><br/>
                                        </li>
                                        <li><span>Patch Application & Rotation Tutorial (online)</span><br/>
								         
                                         <a href="/resources/how-to-apply-patch/" className="external-link no-underline">
                                         Get step-by-step instructions on how to properly apply, rotate, and dispose of the Butrans patch</a>.
                                         </li>
                                            </ul>
							</Col>
						</Row>
                        <br/>
                        <Row>
                            <Col xs={12}>
                            <h1>Risk Evaluation and Mitigation Strategy (REMS)</h1>
                            <h2>
									
                Butrans is subject to the Opioid Analgesics REMS
              </h2>
              <p>
              The goal of the REMS is to reduce serious adverse outcomes resulting from inappropriate prescribing, abuse, and misuse of opioid analgesics, while maintaining patient access to pain medications. Adverse outcomes of concern include addiction, unintentional overdose, and death.
              </p>
              <ul className="yellow-bullets">
                <li>
                  <span>FDA has required a REMS for opioid analgesics</span>
                </li>
                <li>
                  <span className="text-normal">
                  <ExternalLink
											externalURL="https://opioidanalgesicrems.com/RpcUI/products.u"
											messageTitle={appConfigs.externalLinkInfo.TITLE}
											messageBody={appConfigs.externalLinkInfo.BODY}
											classNameLink="external-link"
											classNameOverlay={
												appConfigs.externalLinkInfo.OVERLAY_CLASS_NAME
											}
										> Purdue Pharma is a member of the REMS Program Companies
										</ExternalLink>
                  </span>
                </li>
              </ul>
              <h2>
                Butrans should be prescribed only by healthcare professionals
                who are knowledgeable in the use of potent opioids for the
                management of chronic pain.
              </h2>
              <p>
                Documentation and maintenance of careful prescribing and
                treatment records are essential, and should include:
              </p>
              <ul className="yellow-bullets">
                <li>
                  <span>Physician and patient agreement</span>
                </li>
                <li>
                  <span>Proper assessment of the patient</span>
                </li>
                <li>
                  <span>Proper prescribing practices</span>
                </li>
                <li>
                  <span>Periodic reevaluation of therapy</span>
                </li>
                <li>
                  <span>
                    Proper dispensing and correct storage and handling
                  </span>
                </li>
                <li>
                  <span>
                    Careful record keeping of prescribing information, including
                    quantity, frequency, and renewal requests, is strongly
                    advised
                  </span>
                </li>
                <li>
                  <span className="text-normal">
                    Providing patient and/or caregiver with the{' '}
                    <ExternalLink
												externalURL='https://dailymed.nlm.nih.gov/dailymed/fda/fdaDrugXsl.cfm?setid=794aa355-66de-41b8-aedf-f2c40f6bc664&type=display#section-17'
												messageTitle={appConfigs.dailymedLinkInfo.TITLE}
												messageBody={appConfigs.dailymedLinkInfo.BODY}
												classNameLink='external-link'
												classNameOverlay={
													appConfigs.externalLinkInfo.OVERLAY_CLASS_NAME
												}
											>
											Medication Guide
											</ExternalLink>
                  </span>
                </li>
                <li>
                  <span className="text-normal">
                    Reviewing the Opioid REMS{' '}
                    <ExternalLink
												externalURL='https://www.opioidanalgesicrems.com/patientCounselingGuide.html'
												messageTitle={appConfigs.externalLinkInfo.TITLE}
												messageBody={appConfigs.externalLinkInfo.BODY}
												classNameLink='external-link'
												classNameOverlay={
													appConfigs.externalLinkInfo.OVERLAY_CLASS_NAME
												}
											>
												  Patient Counseling Guide
											</ExternalLink>{' '}
                    with the patient and/or caregiver at the time of prescribing
                  </span>
                </li>
              </ul>
              <span className="text-normal">
                  For more information, including REMS-compliant educational CME/CE programs on safe prescribing of REMS for opioid analgesics, visit{" "} 
                    <ExternalLink
												externalURL='https://www.opioidanalgesicrems.com/'
												messageTitle={appConfigs.externalLinkInfo.TITLE}
												messageBody={appConfigs.externalLinkInfo.BODY}
												classNameLink='external-link'
												classNameOverlay={
													appConfigs.externalLinkInfo.OVERLAY_CLASS_NAME
												}
											>
												 opioidanalgesicrems.com
											</ExternalLink>.{' '}
              
                  </span>
            
                            </Col>
                        </Row>
					</section>

        </div>
      </div>
    </Layout>
  );
};

export default ResourcesPage;
